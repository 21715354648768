<template>
    <div class="page1">
        <EForm
                :formColumns="searchFormColumns"
                :rowSize="4"
                :optionsBtn="true"
                :actionBtn="false"
                :formData="searchForm"
                ref="form"
                @onSearch="onSearch"
                :searchFlag="true"
                :exportShow="false"
        ></EForm>
        <EButton @click="dialogVisible=true">新增</EButton>
        <el-button type="primary" size="small" @click="openPermission">权限设置</el-button>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
                :needSelect="true"
                @handleSelect="handleSelect"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton @click="editDialog(scope.row)" type="text">修改</EButton>
                        <el-button type="text " @click="deleteDept(scope.row)">删除
                        </el-button>
                        <!--                        <el-button type="text " @click="deleteDept(scope.row)">编辑权限-->
                        <!--                        </el-button>-->
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="handleClose" @handleClick="saveData">
            <EForm :formColumns="formColumns"
                   :labelPosition="labelPosition"
                   :rowSize="1"
                   :optionsBtn="false"
                   :labelWidth="labelWidth"
                   :actionBtn="false"
                   :formData="form"
                   :formRules="formRules"
                   ref="form"
                   :searchFlag="false"
            ></EForm>
        </EDialog>
        <el-dialog
                title="角色权限配置"
                :visible.sync="permissionDialog"
                @handleClose="handleClose"
                width="30%">
            <div class="maxHeight">
<!--                <ETree ref="tree" :data="data"></ETree>-->
                <el-tree
                        :data="data"
                        show-checkbox
                        node-key="id"
                        :default-expanded-keys="[1]"
                        :props="defaultProps"
                        ref="tree"
                >
                </el-tree>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="saveNodes">确 定</el-button>
  </span>

        </el-dialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import ETree from '@/components/ETree'
  import vxRule from "@/assets/js/formValidate";

  export default {
    name: 'Login',
    data() {
      return {
        tableTitle: [
          {
            label: '角色名称',
            prop: 'roleName',
          },
          {
            label: '角色别名',
            prop: 'roleAlias',
          },
        ],
        tableData: [],
        loading: false,
        dialogVisible: false,
        title: '添加角色',
        searchForm: {
          roleName: '',
          roleAlias: '',
        },
        form: {
          roleName: '',
          roleAlias: '',
          id: ''
        },
        formRules: {
          roleName: [vxRule(true, '', "blur", "角色名称不能为空"),
            vxRule(true, val => {
              return {result: val.length <= 20, errMsg: "最多可录入20字"}
            })],
        },
        searchFormColumns: [
          {
            title: '角色名称',
            type: 'text',
            property: 'roleName',
            placeHolder: '最多可录入20字',
            require: true,
            show: true
          },
          {
            title: '角色别名',
            type: 'text',
            property: 'roleAlias',
            placeHolder: '最多可录入20字',
            require: true,
            show: true
          },
          {
            type: 'actionBtn',
            show: false
          }
        ],
        formColumns: [
          {
            title: '角色名称',
            type: 'text',
            property: 'roleName',
            placeHolder: '最多可录入20字',
            require: true,
            show: true
          },
          {
            title: '角色别名',
            type: 'text',
            property: 'roleAlias',
            placeHolder: '最多可录入20字',
            require: true,
            show: true
          },
        ],
        labelPosition: 'right',
        labelWidth: '100px',
        permissionDialog: false,
        data: [],
        defaultProps: {
          children: 'childrens',
          label: 'name'
        },
        selectedRole: []
      }
    },
    watch: {},
    created() {
      this.getData()
      this.routes()

    },
    components: {ETable, EDialog, EButton, EForm,ETree},
    beforeMount() {

    },
    mounted() {

    },
    methods: {
      async roleGrant(menuIds, roleIds) {
        let res = await Http.roleGrant({
          menuIds,
          roleIds
        })
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.handleClose()
          this.getData()
        }
      },
      handleSelect(data) {
        this.selectedRole = data
      },
      openPermission() {
        if (this.selectedRole.length == 0) {
          this.$message.warning('必选先选择一个角色')
        } else if (this.selectedRole.length > 1) {
          this.$message.warning('只能选择一个角色')
        } else {
          let arr = []
          let menuList = this.selectedRole[0].menuList
          if (menuList.length > 0) {
            this.getChecked(menuList,(item)=>{
              arr.push(item.id)
            })
          }
          console.log(arr)
          this.permissionDialog=true

          this.$nextTick(()=>{
            // this.$refs.tree.setCheckedKeys(arr);
            // console.log(arr)
              arr.forEach((i,n) => {
                let node = this.$refs.tree.getNode(i);
                // console.log(node)
                // console.log(node.isLeaf)
                // if(node.isLeaf){
                  this.$refs.tree.setChecked(node, true);
                // }
              });
            // {"menuIds":[3,4,19,87,88,89,90,5,91,92,93,94,95,6,96,97,98,99,100,7,101,102,103,104,105,8,106,107,108,109,110,73,111,112,113,114,115,190,74,116,117,118,119,120,191,75,121,122,123,124,125,196,76,126,127,128,129,130,77,131,132,133,134,135,192,78,136,137,138,139,140,193,79,141,142,143,144,145,80,146,147,148,149,150,81,151,152,153,154,155,194,82,156,157,158,159,160,195,84,166,167,168,169,170,72,85,171,172,173,174,175,188,219,220,221,222,252,253,225,212,213,214,223,224,231,206,216,202,215,217,218,243,209,210,245,211,247,226,227,232,228,230,233,234,235,229,236,237,249,250,251,238,239,244,248,240,241,246,254,255,242,275,274,276,71,197,201,207,208],"roleIds":2}
              // this.getChecked(this.data,(item)=>{
              //   if(item.id==id){
              //     item.checked=true
              //   }
              // })
          })

          // //已经设置过的菜单权限赋值
          // setTimeout(() => {
          //
          // }, 500)

        }

      },
      getChecked(arr,fn){
        arr.forEach(item=> {
          fn(item)
          if ((item.childrens && item.childrens.length > 0)||(item.children && item.children.length > 0)) {
            this.getChecked(item.childrens?item.childrens:item.children,fn)
          }
        })
      },
      saveNodes() {
        let menuIds = this.$refs.tree.getCheckedKeys()
        let halfmenuIds = this.$refs.tree.getHalfCheckedKeys()
        let roleIds = this.selectedRole[0].id
        this.roleGrant([...menuIds,...halfmenuIds], roleIds)
      },
      onSearch() {
        this.getData()
      },
      handleClose() {
        this.title = '添加角色'
        this.dialogVisible = false
        this.form = {
          roleName: '',
          roleAlias: '',
          id: ''
        }
        this.permissionDialog = false
        // this.$refs.form.$refs.form.resetFields()
        // this.getChecked(this.data,(item)=>{
        //   this.$set(item,'checked',false)
        // })
        // this.selectedRole=[]
        this.$refs.tree&&this.$refs.tree.setCheckedKeys([]);
      },
      async getData() {
        let res = await Http.roleList(this.searchForm)
        this.tableData = res.data
      },
      saveData() {
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid && !this.error) {
            this.addBtn()
          } else {
            return false;
          }
        });
      },
      async addBtn() {
        let res = await Http.roleSubmit(this.form)
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.handleClose()
          this.getData()
        }

      },
      editDialog(data) {
        this.title = '编辑角色'
        this.form.roleName = data.roleName
        this.form.roleAlias = data.roleAlias
        this.form.id = data.id
        this.dialogVisible = true
      },
      deleteDept(data) {
        this.$messageBox.confirm('确定删除该角色吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.roleRemove(data.id)
        }).catch(res => {

        });
      },
      async roleRemove(ids) {
        let res = await Http.roleRemove({ids})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }

      },
      async routes() {
        let res = await Http.routes()
        if (res.code == 200) {
          this.data = res.data
          this.getChecked(this.data,(item)=>{
            this.$set(item,'checked',false)
          })
        }
      },
    }
  }
</script>

<style lang="scss">

</style>

