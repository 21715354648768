<template>
    <ul>
        <li v-for="(item,index) in data" :key="index">
            <i class="el-icon-caret-right" @click="getchildren($event,'tree',index)" ref="tree" v-if="item.childrens&&item.childrens.length>0"></i>

            <el-checkbox v-model="item.checked" :label="item.id" @change="getKeyChecked"> {{item.name}}</el-checkbox>
            <ul class="tree-list" ref="tree">
                <li v-for="(sub,sIndex) in item.childrens" :key="sIndex">
                    <i class="el-icon-caret-right" @click="getchildren($event)" ref="icon" v-if="sub.childrens&&sub.childrens.length>0"></i>
                    <el-checkbox v-model="sub.checked" :label="sub.id"  @change="getKeyChecked"> {{sub.name}}</el-checkbox>
                    <ul class="tree-list ml20" ref="tree2">
                        <li v-for="(lowest,lIndex) in sub.childrens" :key="lIndex">
                            <i class="el-icon-caret-right" @click="getchildren($event)" ref="icon" v-if="lowest.childrens&&lowest.childrens.length>0"></i>
                            <el-checkbox v-model="lowest.checked" :label="lowest.id" @change="getKeyChecked"> {{lowest.name}}</el-checkbox>
                            <ul class="tree-list ml20" ref="tree3">
                                <li v-for="(s,sIndex) in lowest.childrens" :key="sIndex">
                                    <el-checkbox v-model="s.checked" :label="s.id"> {{s.name}}</el-checkbox>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

        </li>
<!--        <li>-->
<!--            <i class="el-icon-caret-right"></i>-->
<!--            <el-radio v-model="radio" label="2" class="tree-radio">区域2</el-radio>-->
<!--            <ul class="tree-list">-->
<!--                <li>-->
<!--                    摊位1-->
<!--                </li>-->
<!--            </ul>-->

<!--        </li>-->
    </ul>
</template>

<script>
  export default {
    name: "index.vue",
    props:{
      data: {
        type:Array,
        default: ()=>[],
      },
    },
    data(){
      return{
        radio:'',
        className:'el-icon-caret-right',
        // data:[
        //   {
        //     label:'区域',
        //     value:'1',
        //     childrens:[
        //       {
        //         label:'摊位1',
        //         value:'2'
        //       },
        //       {
        //         label:'摊位1',
        //         value:'3'
        //       }
        //     ]
        //   },
        //   {
        //     label:'区域2',
        //     value:'4',
        //     childrens:[
        //       {
        //         label:'摊位1',
        //         value:'5'
        //       },
        //       {
        //         label:'摊位1',
        //         value:'6'
        //       }
        //     ]
        //   }
        // ]
      }
    },
    created() {
      console.log(this.data)
    },
    methods:{
      getchildren(e){


        let className=e.target.className
        e.target.className=className=='el-icon-caret-right'?'el-icon-caret-bottom':'el-icon-caret-right'
        // let className=this.$refs[`icon${icon}`].className
        // this.$refs[`icon${icon}`].className=className=='el-icon-caret-right'?'el-icon-caret-bottom':'el-icon-caret-right'
        let currentName=e.target.className
        e.target.nextSibling.nextSibling.style.height='auto'
        if(currentName=='el-icon-caret-right'){
          e.target.nextSibling.nextSibling.style.height='0'
          e.target.nextSibling.nextSibling.style.overflow='hidden'
        }else{
          e.target.nextSibling.nextSibling.style.height='auto'
          e.target.nextSibling.nextSibling.style.overflow='auto'
        }
      },
      setChecked(arr,checked){
        arr.forEach((id=>{
          this.data.forEach(item=>{
            if(item.id==id){
              item.checked=checked
                console.log(checked)
            }else{

              if(item.childrens&&item.childrens.length>0){
                item.childrens.forEach((s)=>{
                  if(s.id==id){
                    s.checked=checked
                  }else{
                    if(s.childrens&&s.childrens.length>0){
                      s.childrens.forEach((l)=>{
                        if(l.id==id){
                          l.checked=checked
                        }
                      })
                    }
                  }
                })

              }
            }

          })
        }))

        console.log(this.data)
      },
      getKeyChecked(){
        console.log(this.data)
        let keys=[]
        this.getKey(this.data,keys)
        console.log(keys)
        return keys
      },
      getKey(arr,keys){
        arr.forEach(item=>{
          if(item.checked){
            keys.push(item.id)
          }
          if(item.childrens&&item.childrens.length>0){
            this.getKey(item.childrens,keys)
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .tree-list{
        padding-left: 24px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: #606266;
        height:0;
        overflow: hidden;
    }


    .el-icon-caret-right,.el-icon-caret-bottom{
        color:#606266;
        padding-right: 10px;
        cursor: pointer;
    }
    .tree-radio.is-checked+.tree-list{
        color:#4d68ff;
    }

</style>
